import React, { Component, Fragment } from "react";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";
import { Form } from "react-bootstrap";
import { jsPDF } from "jspdf";

// import component
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import KycUpdate from "../partials/KycUpdate";

// import action
import { getAllUserKyc, updateCitizen } from "../../actions/userKycAction";

// import library
import { toastAlert } from "../../lib/toastAlert";
import { firstLetterCase } from "../../lib/capitalize";

class UserKyc extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "user.email",
        text: "Email",
        className: "identifier",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <Fragment>{record.user.email ? record.user.email : "-"}</Fragment>;
        },
      },
      {
        key: "user.phoneNo",
        text: "Phone No",
        className: "identifier",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              {record.user.phoneNo ? `+${record.user.phoneCode}${record.user.phoneNo}` : "-"}
            </Fragment>
          );
        },
      },
      {
        key: "idProof.status",
        text: "Identity Document",
        className: "subject",
        align: "left",
        sortable: true,
        cell: (record) => {
          if (record.idProof.status == "new") {
            return "-";
          } else if (record.idProof.status == "pending") {
            return (
              <>
                {record.idProof.status.charAt(0).toUpperCase() +
                  record.idProof.status.slice(1)}
                <span>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.editRecord(record, "idProof")}
                    style={{ marginRight: "5px" }}
                  >
                    View
                  </button>
                </span>
              </>
            );
          } else {
            return (
              <>
                {record.idProof.status.charAt(0).toUpperCase() +
                  record.idProof.status.slice(1)}
                <span>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.editRecord(record, "idProof")}
                    style={{ marginRight: "5px" }}
                  >
                    View
                  </button>
                </span>
              </>
            );
          }
        },
      },
      {
        key: "addressProof.status",
        text: "Residential Document",
        className: "subject",
        align: "left",
        sortable: true,
        cell: (record) => {
          if (record.addressProof.status == "new") {
            return "-";
          } else if (record.addressProof.status == "pending") {
            return (
              <>
                {record.addressProof.status.charAt(0).toUpperCase() +
                  record.addressProof.status.slice(1)}
                <span>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.editRecord(record, "addressProof")}
                    style={{ marginRight: "5px" }}
                  >
                    View
                  </button>
                </span>
              </>
            );
          } else {
            return (
              <>
                {" "}
                {record.addressProof.status.charAt(0).toUpperCase() +
                  record.addressProof.status.slice(1)}
                <span>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.editRecord(record, "addressProof")}
                    style={{ marginRight: "5px" }}
                  >
                    View
                  </button>
                </span>
              </>
            );
          }
        },
      },
      {
        key: "addressProof.citizen",
        text: "Citizenship Status",
        className: "citizen",
        align: "left",
        sortable: true,
        width: 100,
        cell: (record) => {
          return (
            <Fragment>
              {record.addressProof.citizen != null
                ? record.addressProof.citizen.charAt(0).toUpperCase() +
                  record.addressProof.citizen.slice(1)
                : "-"}
            </Fragment>
          );
        },
      },
      {
        key: "citizen",
        text: "Citizen(Indian User)",
        className: "citizen",
        align: "left",
        sortable: false,
        cell: (record) => {
          if (record.addressProof.citizen != "") {
            return (
              <>
                <div className="d-flex justify-content-around">
                  {record.addressProof.citizen != "yes" && (
                    <span>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        className="btn btn-primary btn-sm btn-success"
                        onClick={() => this.handleCitizen(record, "yes")}
                        style={{ marginRight: "5px" }}
                      >
                        Yes(INDIA)
                      </button>
                    </span>
                  )}

                  {record.addressProof.citizen != "no" && (
                    <span>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        className="btn btn-primary btn-sm btn-danger"
                        onClick={() => this.handleCitizen(record, "no")}
                        style={{ marginRight: "5px" }}
                      >
                        No(Other)
                      </button>
                    </span>
                  )}
                </div>
              </>
            );
          }
        },
      },
    ];

    this.state = {
      records: [],
      totalCount: 0,
      offset: 0,
      pageSize: 10,
      sortOrder: {
        column: "_id",
        order: "desc",
      },
      loading: true,
      exportType: "current",
      extraButtons: [{
        className: "btn btn-primary buttons-pdf",
        title: "Export PDF",
        children: [
          <span>
            <i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i>
          </span>,
        ],
        onClick: (event) => {
          this.exportPDF();
        },
      },
      {
        className: "btn btn-primary buttons-csv",
        title: "Export CSV",
        children: [
          <span>
            <i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i>
          </span>
        ],
        onClick: (event) => {
          this.exportCSV();
        },
      }],
      docFormModal: false,
      docRecord: {},
    };

    this.config = {
      page_size: this.state.limit,
      sort: this.state.sortOrder,
      length_menu: [10, 20, 50],
      no_data_text: "No KYC found!",
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      key_column: "_id",
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      pagination: 'advance',
      show_info: true,
      button: {
        extra: true,
      }
    };

    this.getData = this.getData.bind(this);
    this.refetch = this.refetch.bind(this);
    this.handleCloseDocForm = this.handleCloseDocForm.bind(this);
    this.handleCitizen = this.handleCitizen.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (filter = "") => {
    try {
      let data = {
        sortOrder:this.state.sortOrder,
        filter,
        limit: this.state.pageSize,
        offset: this.state.offset,
      };
      let { status, totalCount, result } = await getAllUserKyc(data);
      if (status) {
        this.setState({ loading: false });
        this.setState({ records: result, totalCount });
      }
    } catch (e) {}
  }

  async handleCitizen(record, data) {
    let reqData = {
      userId: record.userId,
      id: record._id,
      citizen: data,
    };
    const { status, loading, message, result } = await updateCitizen(reqData);
    if (status == "success") {
      toastAlert("success", message, "kycform");
      this.refetch();
    } else {
      toastAlert("error", message, "kycform");
    }
  }

  editRecord(record, formType) {
    if (formType == "idProof") {
      let docRecord = {
        userId: record.userId,
        type: record.idProof.type,
        proofNumber: record.idProof.proofNumber,
        frontImage: record.idProof.frontImage,
        backImage: record.idProof.backImage,
        selfiImage: record.idProof.selfiImage,
        status: record.idProof.status,
        formType,
      };

      this.setState({ docFormModal: true, docRecord });
    } else if (formType == "addressProof") {
      let docRecord = {
        userId: record.userId,
        type: record.addressProof.type,
        frontImage: record.addressProof.frontImage,
        backImage: record.addressProof.backImage,
        status: record.addressProof.status,
        formType,
      };

      this.setState({ docFormModal: true, docRecord });
    }
  }

  search = data => {
    let filter = data.filter_value;
    let pageSize = data.page_size;
    let offset = (data.page_number-1)*pageSize;
    let sortOrder = data.sort_order ? data.sort_order:this.state.sortOrder;
    this.setState({ records:[], offset, pageSize, sortOrder, filter, loading: true }, function() {
      this.getData(filter);
    }); 
  }

  handleCloseDocForm() {
    this.setState({ docFormModal: false });
  }

  refetch() {
    this.getData();
  }

  exportCSV = async () => {

    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let data = {
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });


    let { status, result } = await getAllUserKyc(data);

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[1].children = [
        <span><i className="glyphicon glyphicon-print fa fa-file-excel-o" aria-hidden="true"></i></span>
      ];
      return { extraButtons };
    });

    let records = status ? result: [];
    let separator = ";";
    let headers = [
      "Email",
      "Phone No",
      "Identity Document",
      "Residential Document",
      "Citizenship Status"
    ];

    let rows = [];
    records.length > 0 && records.map((elt) => rows.push({
      "Email": elt.user && elt.user.email ? elt.user.email:"-",
      "Phone No": elt.user && elt.user.phoneNo ? `+${elt.user.phoneCode}${elt.user.phoneNo}` : "-",
      "Identity Document": elt.idProof.status && elt.idProof.status!="new" ? firstLetterCase(elt.idProof.status):"-",
      "Residential Document": elt.addressProof.status && elt.addressProof.status!="new" ? firstLetterCase(elt.addressProof.status):"-",
      "Citizenship Status": elt.addressProof.citizen ? firstLetterCase(elt.addressProof.citizen):"-"
    }));

    let csvContent = `${headers.join(separator)}\n${rows.map((row) =>
      headers.map((k) => {
        let cell = row[k] === null || row[k] === undefined ? "" : row[k];

        cell = cell instanceof Date ? cell.toLocaleString():cell.toString().replace(/"/g, '""');

        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator)
    ).join("\n")}`;

    let title = "KYC";
    let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // In case of IE 10+
      navigator.msSaveBlob(blob, title);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", title);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  exportPDF = async () => {

    let { sortOrder, filter, pageSize, offset, totalCount, exportType } = this.state;
    let data = {
      sortOrder,
      filter,
      limit: exportType == "current" ? pageSize:totalCount,
      offset: exportType == "current" ? offset:0,
      download: 1
    };

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [<span><i className="fa fa-spinner fa-spin" aria-hidden="true"></i></span>];
      return { extraButtons };
    });


    let { status, result } = await getAllUserKyc(data);

    this.setState((prevState) => {
      let extraButtons = [...prevState.extraButtons];
      extraButtons[0].children = [
        <span><i className="glyphicon glyphicon-print fa fa-file-pdf-o" aria-hidden="true"></i></span>
      ];
      return { extraButtons };
    });

    let records = status ? result: [];

    let unit = "pt";
    let size = "A4"; // Use A1, A2, A3 or A4
    let orientation = "landscape"; // portrait or landscape

    let marginLeft = 40;
    let doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(13);

    let title = "KYC";
    let headers = [[
      "Email",
      "Phone No",
      "Identity Document",
      "Residential Document",
      "Citizenship Status"
    ]];

    let downloadData = records.length > 0 && records.map((elt) => [
      elt.user && elt.user.email ? elt.user.email:"-",
      elt.user && elt.user.phoneNo ? `+${elt.user.phoneCode}${elt.user.phoneNo}` : "-",
      elt.idProof.status && elt.idProof.status!="new" ? firstLetterCase(elt.idProof.status):"-",
      elt.addressProof.status && elt.addressProof.status!="new" ? firstLetterCase(elt.addressProof.status):"-",
      elt.addressProof.citizen ? firstLetterCase(elt.addressProof.citizen):"-"
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: downloadData,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("KYC.pdf");
  }

  render() {
    const { docFormModal, docRecord } = this.state;

    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar menuKey={this.props.menuKey} />
          <KycUpdate
            isShow={docFormModal}
            onHide={this.handleCloseDocForm}
            fetchData={this.refetch}
            record={docRecord}
          />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="float-xl-right">
                Export
                {["current", "all"].map((v) => (
                  <Form.Check
                    inline
                    type="radio"
                    name="exportType"
                    value={v}
                    key={v}
                    onChange={(e) => this.setState({ exportType: e.target.value })}
                    defaultChecked={v == this.state.exportType}
                    label={firstLetterCase(v)}
                  />
                ))}
              </div>
              <h3 className="mt-2 text-secondary">KYC List</h3>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                dynamic={true}
                total_record={this.state.totalCount}
                loading={this.state.loading}
                onChange={this.search}
                extraButtons={this.state.extraButtons}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserKyc;