import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { NavLink } from "react-router-dom";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { Scrollbars } from "react-custom-scrollbars";
import store from "../../store";
import { Alert } from "bootstrap";
import { Accordion, Card } from "react-bootstrap";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: window.innerWidth > 768 ? true : false,
      activeKey: this.props.menuKey ? this.props.menuKey : "0",
    };
  }
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  componentDidMount() {
    window.addEventListener("resize", this.changestate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.changestate);
  }

  changestate = (e) => {
    this.setState({ show: this.state.show ? false : true });
  };

  render() {
    const { user } = this.props.auth.user;
    const userData = store.getState();
    return (
      <div>
        <button className="btn" onClick={this.changestate} id="menu-toggle">
          <FontAwesomeIcon icon={faList} />
        </button>
        {this.state.show ? (
          <div className="border-right">
            <div className="list-group list-group-flush">
              <Scrollbars style={{ width: 250, height: "88.5vh" }}>
                {this.props.auth.user.moderator == "1" ? (
                  <div>
                    <NavLink
                      to="/users"
                      className="list-group-item list-group-item-action"
                    >
                      Users
                    </NavLink>
                    <NavLink
                      to="/P2PChat"
                      className="list-group-item list-group-item-action"
                    >
                      P2P Chat
                    </NavLink>
                  </div>
                ) : (
                  <div>
                    <Accordion defaultActiveKey={this.state.activeKey}>
                      <NavLink
                        to="/dashboard"
                        className="list-group-item list-group-item-action"
                      >
                        Dashboard
                      </NavLink>
                      {userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/sub-admins"
                          className="list-group-item list-group-item-action"
                        >
                          Sub Admins
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {(userData.auth.user.User == true ||
                        userData.auth.user.Kyc == true ||
                        userData.auth.user.UserBalance == true ||
                        userData.auth.user.DepositList == true ||
                        userData.auth.user.WithdrawList == true ||
                        userData.auth.user.role == "superadmin") && (
                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey="1">
                            User Management
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              {userData.auth.user.User == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/users"
                                  className="list-group-item list-group-item-action"
                                >
                                  Users
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.Kyc == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/kyc"
                                  className="list-group-item list-group-item-action"
                                >
                                  KYC
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.UserBalance == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/Userbalance"
                                  className="list-group-item list-group-item-action"
                                >
                                  Wallet Balance
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.DepositList == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/deposit"
                                  className="list-group-item list-group-item-action"
                                >
                                  Deposit History
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.WithdrawList == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/withdraw"
                                  className="list-group-item list-group-item-action"
                                >
                                  Withdraw History
                                </NavLink>
                              ) : (
                                ""
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )}

                      {(userData.auth.user.SpotPair == true ||
                        userData.auth.user.SpotOrderHistory == true ||
                        userData.auth.user.SpotTradeHistory == true ||
                        userData.auth.user.SpotCommision == true ||
                        userData.auth.user.TradingBot == true ||
                        userData.auth.user.role == "superadmin") && (
                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey="2">
                            Spot Management
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="2">
                            <Card.Body>
                              {userData.auth.user.SpotPair == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/Pairmanagement"
                                  className="list-group-item list-group-item-action"
                                >
                                  Spot Pair
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.SpotOrderHistory == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/orderhistory"
                                  className="list-group-item list-group-item-action"
                                >
                                  Spot Order History
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.SpotTradeHistory == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/tradehistory"
                                  className="list-group-item list-group-item-action"
                                >
                                  Spot Trade History
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.SpotCommision == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/SpotCommisionHistory"
                                  className="list-group-item list-group-item-action"
                                >
                                  Spot Commision History
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.TradingBot == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/trading-bot"
                                  className="list-group-item list-group-item-action"
                                >
                                  Trading Bot
                                </NavLink>
                              ) : (
                                ""
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )}

                      {(userData.auth.user.p2ppair == true || 
                        userData.auth.user.p2pAds == true ||
                        userData.auth.user.p2ptradehistory == true ||
                        userData.auth.user.p2pdisputelist == true ||
                        userData.auth.user.p2pcommissionhistory == true ||
                        userData.auth.user.role == "superadmin") && (
                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey="3">
                            P2P Management
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="3">
                            <Card.Body>
                              {userData.auth.user.p2ppair == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/P2PPairmanagement"
                                  className="list-group-item list-group-item-action"
                                >
                                  P2P Trade Pair
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.p2pAds == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/postads"
                                  className="list-group-item list-group-item-action"
                                >
                                  P2P Post Ads{" "}
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.p2ptradehistory == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/p2ptrade"
                                  className="list-group-item list-group-item-action"
                                >
                                  P2P Trade History{" "}
                                </NavLink>
                              ) : (
                                ""
                              )}

                              {userData.auth.user.p2pdisputelist == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/p2pdispute"
                                  className="list-group-item list-group-item-action"
                                >
                                  P2P Dispute Trade
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.p2pcommissionhistory ==
                                true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/adminrevenue"
                                  className="list-group-item list-group-item-action"
                                >
                                  P2P Commision History
                                </NavLink>
                              ) : (
                                ""
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )}

                      {(userData.auth.user.copyTradeSettings == true ||
                        userData.auth.user.copyTradeApplications == true ||
                        userData.auth.user.topTraders == true ||
                        userData.auth.user.followers == true ||
                        userData.auth.user.CopyOrderHistory == true ||
                        userData.auth.user.CopyTradeHistory == true ||
                        userData.auth.user.bannedTraders == true ||
                        userData.auth.user.badges == true ||
                        userData.auth.user.subscription == true ||
                        userData.auth.user.subscribers == true ||
                        userData.auth.user.adminProfit == true ||
                        userData.auth.user.role == "superadmin") && (
                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey="4">
                            Copy Trade Management
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="4">
                            <Card.Body>
                              {userData.auth.user.copyTradeSettings == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/copy-trade-settings"
                                  className="list-group-item list-group-item-action"
                                >
                                  Settings
                                </NavLink>
                              ) : (
                                ""
                              )}

                              {userData.auth.user.copyTradeApplications ==
                                true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/copy-trade-applications"
                                  className="list-group-item list-group-item-action"
                                >
                                  Applications
                                </NavLink>
                              ) : (
                                ""
                              )}

                              {userData.auth.user.topTraders == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/top-traders"
                                  className="list-group-item list-group-item-action"
                                >
                                  Top Traders
                                </NavLink>
                              ) : (
                                ""
                              )}

                              {userData.auth.user.followers == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/followers"
                                  className="list-group-item list-group-item-action"
                                >
                                  Followers
                                </NavLink>
                              ) : (
                                ""
                              )}

                              {userData.auth.user.CopyOrderHistory == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/copyorderhistory"
                                  className="list-group-item list-group-item-action"
                                >
                                  Order History
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.CopyTradeHistory == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/copytradehistory"
                                  className="list-group-item list-group-item-action"
                                >
                                  Trade History
                                </NavLink>
                              ) : (
                                ""
                              )}

                              {userData.auth.user.bannedTraders == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/banned-traders"
                                  className="list-group-item list-group-item-action"
                                >
                                  Banned Traders
                                </NavLink>
                              ) : (
                                ""
                              )}

                              {userData.auth.user.badges == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/badges"
                                  className="list-group-item list-group-item-action"
                                >
                                  Badges
                                </NavLink>
                              ) : (
                                ""
                              )}

                              {userData.auth.user.subscription == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/subscription"
                                  className="list-group-item list-group-item-action"
                                >
                                  Subscription
                                </NavLink>
                              ) : (
                                ""
                              )}

                              {userData.auth.user.subscribers == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/subscibers"
                                  className="list-group-item list-group-item-action"
                                >
                                  Subscribers
                                </NavLink>
                              ) : (
                                ""
                              )}

                              {userData.auth.user.adminProfit == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/admin-profit"
                                  className="list-group-item list-group-item-action"
                                >
                                  Admin Profit
                                </NavLink>
                              ) : (
                                ""
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )}

                      {(userData.auth.user.Staking == true ||
                        userData.auth.user.StackingOrder == true ||
                        userData.auth.user.StakeSettlement == true ||
                        userData.auth.user.role == "superadmin") && (
                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey="5">
                            Staking Management
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="5">
                            <Card.Body>
                              {userData.auth.user.Staking == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/staking"
                                  className="list-group-item list-group-item-action"
                                >
                                  {" "}
                                  Staking
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.StackingOrder == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/stakingorder"
                                  className="list-group-item list-group-item-action"
                                >
                                  Staking Order History
                                </NavLink>
                              ) : (
                                ""
                              )}
                              {userData.auth.user.StakeSettlement == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/stakingsettle"
                                  className="list-group-item list-group-item-action"
                                >
                                  {" "}
                                  Stake Settlement
                                </NavLink>
                              ) : (
                                ""
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )}

                      {(userData.auth.user.tdsSetting == true ||
                        userData.auth.user.userTDS == true ||
                        userData.auth.user.userTDSTax == true ||
                        userData.auth.user.role == "superadmin") && (
                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey="6">
                            TDS Management
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="6">
                            <Card.Body>
                              {userData.auth.user.tdsSetting == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/tdslist"
                                  className="list-group-item list-group-item-action"
                                >
                                  TDS Settings
                                </NavLink>
                              ) : (
                                ""
                              )}

                              {userData.auth.user.userTDS == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/usertdslist"
                                  className="list-group-item list-group-item-action"
                                >
                                  User TDS List
                                </NavLink>
                              ) : (
                                ""
                              )}

                              {userData.auth.user.userTDSTax == true ||
                              userData.auth.user.role == "superadmin" ? (
                                <NavLink
                                  to="/tdstaxlist"
                                  className="list-group-item list-group-item-action"
                                >
                                  TDS Tax List(Users)
                                </NavLink>
                              ) : (
                                ""
                              )}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      )}

                      {userData.auth.user.Currency == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/currency"
                          className="list-group-item list-group-item-action"
                        >
                          Currency
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.PriceConversation == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/priceCNVlist"
                          className="list-group-item list-group-item-action"
                        >
                          Price Conversion
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.Announcement == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/anouncement"
                          className="list-group-item list-group-item-action"
                        >
                          Anouncement
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.ContactUs == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/contactus"
                          className="list-group-item list-group-item-action"
                        >
                          Contact Us
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.newsletter == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/newsletter"
                          className="list-group-item list-group-item-action"
                        >
                          Newsletter
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.SupportCategory == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/support-category"
                          className="list-group-item list-group-item-action"
                        >
                          Support Category
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.Support == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/support"
                          className="list-group-item list-group-item-action"
                        >
                          Support
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.EmailTemplate == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/emailtemplates"
                          className="list-group-item list-group-item-action"
                        >
                          Email Templates
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.FaqCategory == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/faq-category"
                          className="list-group-item list-group-item-action"
                        >
                          FAQ Category
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.Faq == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/faq"
                          className="list-group-item list-group-item-action"
                        >
                          FAQ
                        </NavLink>
                      ) : (
                        ""
                      )}
                      {userData.auth.user.CmsPage == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/cms"
                          className="list-group-item list-group-item-action"
                        >
                          CMS Pages{" "}
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {userData.auth.user.homepagecms == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/homepagecms"
                          className="list-group-item list-group-item-action"
                        >
                          Home Page Cms
                        </NavLink>
                      ) : (
                        ""
                      )}

                      {/* {userData.auth.user.airdrop == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/airdrop"
                          className="list-group-item list-group-item-action"
                        >
                          AirDrop
                        </NavLink>
                      ) : (
                        ""
                      )} */}
                      {userData.auth.user.passbook == true ||
                      userData.auth.user.role == "superadmin" ? (
                        <NavLink
                          to="/passbook"
                          className="list-group-item list-group-item-action"
                        >
                          Passbook
                        </NavLink>
                      ) : (
                        ""
                      )}
                    </Accordion>
                  </div>
                )}
              </Scrollbars>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Sidebar);
