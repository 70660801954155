import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import React, { Component } from "react";
import Login from "./components/auth/Login";
import Forgot from "./components/auth/Forgot";
import NotFound from "./components/layout/NotFound";
import { Provider } from "react-redux";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Register from "./components/auth/Register";
import store from "./store";
import jwt_decode from "jwt-decode";
import { setCurrentUser } from "./actions/authActions";

// import component
import ConditionRoute from "./components/Route/ConditionRoute";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "../node_modules/font-awesome/css/font-awesome.css";
import "../node_modules/jquery/dist/jquery.min";
import "../node_modules/popper.js/dist/popper";
import User from "./components/pages/Users";
import Bonus from "./components/pages/Bonus";
import Emailtemplates from "./components/pages/Emailtemplates";
import Pairmanagement from "./components/pages/Pairmanagement";
import Cms from "./components/pages/Cms";
import Profile from "./components/pages/Profile.jsx";
import Settings from "./components/pages/Settings";
import Changepassword from "./components/pages/Changepassword";
import LoginHistory from "./components/partials/LoginHistory.js";

import Withdraw from "./components/pages/Withdraw";
import Deposit from "./components/pages/Deposit";
import Userbalance from "./components/pages/Userbalance";
//   import Liquidated from "./components/pages/Liquidated";
import PriceConversion from "./components/pages/priceConversion";
import Perpetual from "./components/pages/Perpetual";
import Contactus from "./components/pages/Contactus";
import Chat from "./components/pages/Chat";
import Newsletter from "./components/pages/Newsletter";
import SupportCategory from "./components/pages/supportCategory";
import Support from "./components/pages/Support";
import SupportReply from "./components/pages/SupportReply";
import Currency from "./components/pages/Currency";
import TradeHistory from "./components/pages/Tradehistory";
import SpotCommisionHistoryPage from "./components/pages/SpotCommisionHistory";
import Closedpositions from "./components/pages/Closedpositionshistory";
import OrderHistory from "./components/pages/Orderhistory";
//   import PerpetualOrderHistory from "./components/pages/perputualOrderHoitory";
//   import PerpetualTradeHistory from "./components/pages/perpetualTradeHistory";
//   import FeeSettings from "./components/pages/Feesettings.jsx";
import Assets from "./components/pages/AssetsExchange.jsx";
import Category from "./components/pages/category.jsx";
//   import Subcategory from "./components/pages/Subcategory.jsx";
import Anouncement from "./components/pages/anouncement";
//   import Article from "./components/pages/Article.jsx";
import Staking from "./components/pages/Staking";
import StakeOrderHist from "./components/pages/ StakeOrderHist";
import StakeSettlement from "./components/pages/StakeSettlement";
//   import HelpCentreCategory from "./components/pages/HelpCentrecategory.jsx";
//   import HelpCentreArticle from "./components/pages/HelpcentreArticle.jsx";
import Kyc from "./components/pages/Kyc";
import kycupdate from "./components/partials/KycUpdate.js";
//   import Tradingbot from "./components/pages/Tradingbot";
//   import Launchpad from "./components/pages/Launchpad";

import SiteSettingPage from "./components/pages/SiteSettingPage";

import SubAdmin from "./components/pages/SubAdmin";
//   import LanguagePage from "./components/pages/LanguagePage";
import FaqCategory from "./components/pages/FaqCategory";
import FaqPage from "./components/pages/Faq";
//   import perpetualTradeHistory from "./components/pages/perpetualTradeHistory";
import P2PPairmanagement from "./components/pages/P2PPairmanagement";
import P2PChat from "./components/pages/p2pchat";
import P2PTrade from "./components/pages/p2ptrade";
import P2PDispute from "./components/pages/p2pdispute";
import p2ptradeview from "./components/pages/p2ptradeview";
import p2pDisputechat from "./components/pages/p2pDisputeChat";
import LaunchpadCms from "./components/pages/LaunchpadCms.jsx";
import AdminRevenue from "./components/pages/adminrevenue";
//   import LaunchpadCms from "./components/pages/LaunchpadCms";
import Airdrop from "./components/pages/Airdrop";
import Airdroplist from "./components/pages/Airdroplist";

//   import StakeOrderHist from "./components/pages/StakeOrderHist";
//   import StakeSettleHist from "./components/pages/StakeSettleHist";

//   import FeesOwnToken from "./components/pages/FeesOwnToken";
//   import ReferralCommission from "./components/pages/ReferralCommission";
//   import twofactor from "./components/pages/twofactor";

import CopyTradeSettings from "./components/pages/copyTradeSettings";
import CopyTradeApplications from "./components/pages/copyTradeApplications";
import TopTraders from "./components/pages/topTraders";
import BannedTraders from "./components/pages/bannedTraders";
import Badges from "./components/pages/badges";
import Subscription from "./components/pages/subscription";
import Subscibers from "./components/pages/subscibers";
import Followers from "./components/pages/followers";
import AdminProfit from "./components/pages/adminProfit";
import CopyOrderHistory from "./components/pages/CopyOrderhistory";
import CopyTradeHistory from "./components/pages/CopyTradehistory";
import TradingBot from "./components/pages/Tradingbot";
import passBook from "./components/pages/passBook";
import TdsTaxList from "./components/pages/TdsTaxList";
import P2PAds from "./components/pages/p2ppostads";

import SettingTDS from "./components/pages/tdsSettings";
import UserTDSList from "./components/pages/UserTDSList";

if (localStorage.getItem("admin_token")) {
  const token = localStorage.getItem("admin_token");
  // setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
  // const currentTime = Date.now() / 1000;
  // if (decoded.exp < currentTime) {
  //     store.dispatch(logoutUser());
  //     window.location.href = "./login";
  // }
}

let userData = store.getState();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ToastContainer />
        <Router basename={"/"}>
          <div className="App">
            <Switch>
              <ConditionRoute exact path="/" component={Login} type="auth" />
              <ConditionRoute
                exact
                path="/register"
                component={Register}
                type="auth"
              />
              <ConditionRoute
                exact
                path="/login"
                component={Login}
                type="auth"
              />
              <ConditionRoute
                exact
                path="/forgot"
                component={Forgot}
                type="auth"
              />

              <Switch>
                <ConditionRoute
                  exact
                  path="/dashboard"
                  component={Dashboard}
                  type={"private"}
                />
                {userData.auth.user.SideSetting == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/site-setting"
                    component={SiteSettingPage}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.CmsPage == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/cms"
                    component={Cms}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.FaqCategory == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/faq-category"
                    component={FaqCategory}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.Faq == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/faq"
                    component={FaqPage}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                {/*<PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
                {userData.auth.user.User == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/users"
                    component={User}
                    type={"private"}
                    menuKey={"1"}
                  />
                ) : (
                  ""
                )}
                <ConditionRoute
                  exact
                  path="/Bonus"
                  component={Bonus}
                  type={"private"}
                />
                <PrivateRoute exact path="/assets" component={Assets} />
                {userData.auth.user.EmailTemplate == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/emailtemplates"
                    component={Emailtemplates}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                <PrivateRoute exact path="/profile" component={Profile} />
                <PrivateRoute exact path="/settings" component={Settings} />
                <PrivateRoute
                  exact
                  path="/changepassword"
                  component={Changepassword}
                />
                {/* <PrivateRoute
                    exact
                    path="/history/stakeOrder"
                    component={StakeOrderHist}
                  />
                  <PrivateRoute
                    exact
                    path="/history/stakeSettlement"
                    component={StakeSettleHist}
                  /> */}
                <ConditionRoute
                  exact
                  path="/perpetual"
                  component={Perpetual}
                  type={"private"}
                />
                {/*{ / priceCNVlist  --  Price Conversion List  / }*/}
                {userData.auth.user.PriceConversation == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/priceCNVlist"
                    component={PriceConversion}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.ContactUs == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/contactus"
                    component={Contactus}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                <ConditionRoute exact path="/chat" component={Chat} />

                {userData.auth.user.Announcement == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/anouncement"
                    component={Anouncement}
                    type={"private"}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.newsletter == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/newsletter"
                    component={Newsletter}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.SupportCategory == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/support-category"
                    component={SupportCategory}
                    type={"private"}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.tdsSetting == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/tdslist"
                    component={SettingTDS}
                    type={"private"}
                    menuKey={"6"}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.userTDS == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/usertdslist"
                    component={UserTDSList}
                    type={"private"}
                    menuKey={"6"}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.userTDSTax == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/tdstaxlist"
                    component={TdsTaxList}
                    type={"private"}
                    menuKey={"6"}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.Support == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/support"
                    component={Support}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.Support == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/support_reply/:id"
                    component={SupportReply}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                {/* {userData.auth.user.Language == true ||
                  userData.auth.user.role == "superadmin" ? (
                    <ConditionRoute
                      exact
                      path="/language"
                      component={LanguagePage}
                      type={"private"}
                    />
                  ) : (
                    ""
                  )} */}
                <ConditionRoute
                  exact
                  path="/login-history"
                  component={LoginHistory}
                  type="private"
                />
                {userData.auth.user.Kyc == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/kyc"
                    component={Kyc}
                    type={"private"}
                    menuKey={"1"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.Currency == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/currency"
                    component={Currency}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                <PrivateRoute
                  exact
                  path="/closedpositions"
                  component={Closedpositions}
                  type={"private"}
                />

                {userData.auth.user.p2pcommissionhistory == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/adminrevenue"
                    component={AdminRevenue}
                    menuKey={"3"}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.p2ptradehistory == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/p2ptrade"
                    component={P2PTrade}
                    menuKey={"3"}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.p2pAds == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/postads"
                    component={P2PAds}
                    menuKey={"3"}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.p2pchathistory == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute exact path="/p2pchat" component={P2PChat} />
                ) : (
                  ""
                )}

                {userData.auth.user.p2pdisputelist == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/p2pdispute"
                    component={P2PDispute}
                    menuKey={"3"}
                  />
                ) : (
                  ""
                )}

                <ConditionRoute
                  exact
                  path="/p2ptradeview/:id"
                  component={p2ptradeview}
                  menuKey={"3"}
                />

                <ConditionRoute
                  exact
                  path="/p2pdisputechat/:id"
                  component={p2pDisputechat}
                />

                {userData.auth.user.copyTradeSettings == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/copy-trade-settings"
                    component={CopyTradeSettings}
                    type={"private"}
                    menuKey={"4"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.copyTradeApplications == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/copy-trade-applications"
                    component={CopyTradeApplications}
                    type={"private"}
                    menuKey={"4"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.topTraders == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/top-traders"
                    component={TopTraders}
                    type={"private"}
                    menuKey={"4"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.CopyOrderHistory == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/copyorderhistory"
                    component={CopyOrderHistory}
                    type={"private"}
                    menuKey={"4"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.CopyTradeHistory == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/copytradehistory"
                    component={CopyTradeHistory}
                    type={"private"}
                    menuKey={"4"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.bannedTraders == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/banned-traders"
                    component={BannedTraders}
                    type={"private"}
                    menuKey={"4"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.badges == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/badges"
                    component={Badges}
                    type={"private"}
                    menuKey={"4"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.subscription == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/subscription"
                    component={Subscription}
                    type={"private"}
                    menuKey={"4"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.subscribers == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/subscibers"
                    component={Subscibers}
                    type={"private"}
                    menuKey={"4"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.followers == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/followers"
                    component={Followers}
                    type={"private"}
                    menuKey={"4"}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.adminProfit == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/admin-profit"
                    component={AdminProfit}
                    type={"private"}
                    menuKey={"4"}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.SpotOrderHistory == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/orderhistory"
                    component={OrderHistory}
                    type={"private"}
                    menuKey={"2"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.SpotTradeHistory == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/tradehistory"
                    component={TradeHistory}
                    type={"private"}
                    menuKey={"2"}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.SpotCommision == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/SpotCommisionHistory"
                    component={SpotCommisionHistoryPage}
                    type={"private"}
                    menuKey={"2"}
                  />
                ) : (
                  ""
                )}
                {/* <ConditionRoute
                    exact
                    path="/perpetual-Trade-History"
                    component={perpetualTradeHistory}
                    type={"private"}
                  />
                  <ConditionRoute
                    exact
                    path="/perpetual-Order-History"
                    component={PerpetualOrderHistory}
                    type={"private"}
                  />
  
  
  
                  <ConditionRoute
                    exact
                    path="/feesettings"
                    component={FeeSettings}
                    type={"private"}
                  />
   */}

                {userData.auth.user.UserBalance == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/Userbalance"
                    component={Userbalance}
                    type={"private"}
                    menuKey={"1"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.WithdrawList == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/withdraw"
                    component={Withdraw}
                    type={"private"}
                    menuKey={"1"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.DepositList == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/deposit"
                    component={Deposit}
                    type={"private"}
                    menuKey={"1"}
                  />
                ) : (
                  ""
                )}
                {/* <ConditionRoute exact path="/liquidated" component={Liquidated}type={"private"} /> 
                                   <ConditionRoute exact path="/subcategory" component={Subcategory} type={"private"}/>
                                   */}
                {userData.auth.user.BlogCategory == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/category"
                    component={Category}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                {/* {userData.auth.user.BlogArticle == true ||
                  userData.auth.user.role == "superadmin" ? (
                    <ConditionRoute
                      exact
                      path="/article"
                      component={Article}
                      type={"private"}
                    />
                  ) : (
                    ""
                  )} */}
                <ConditionRoute
                  exact
                  path="/P2PPairmanagement"
                  component={P2PPairmanagement}
                  menuKey={"3"}
                />
                <ConditionRoute
                  exact
                  path="/Pairmanagement"
                  component={Pairmanagement}
                  type={"private"}
                  menuKey={"2"}
                />
                {/* <ConditionRoute
                    exact
                    path="/HelpCentreCategory"
                    component={HelpCentreCategory}
                    type={"private"}
                  />
                  <ConditionRoute
                    exact
                    path="/HelpCentreArticle"
                    component={HelpCentreArticle}
                    type={"private"}
                  /> */}
                {/* {userData.auth.user.TradingBot == true ||
                  userData.auth.user.role == "superadmin" ? (
                    <ConditionRoute
                      exact
                      path="/Tradingbot"
                      component={Tradingbot}
                      type={"private"}
                    />
                  ) : (
                    ""
                  )} */}

                {userData.auth.user.Staking == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/staking"
                    component={Staking}
                    type={"private"}
                    menuKey={"5"}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.StackingOrder == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/stakingorder"
                    component={StakeOrderHist}
                    type={"private"}
                    menuKey={"5"}
                  />
                ) : (
                  ""
                )}

                {userData.auth.user.StakeSettlement == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/stakingsettle"
                    component={StakeSettlement}
                    type={"private"}
                    menuKey={"5"}
                  />
                ) : (
                  ""
                )}

                {/* {userData.auth.user.Launchpad == true ||
                  userData.auth.user.role == "superadmin" ? (
                    <ConditionRoute
                      exact
                      path="/launchpad"
                      component={Launchpad}
                      type={"private"}
                    />
                  ) : (
                    ""
                  )} */}
                {/* {userData.auth.user.LaunchpadCms == true ||
                  userData.auth.user.role == "superadmin" ? (
                    <ConditionRoute
                      exact
                      path="/launchpadcms"
                      component={LaunchpadCms}
                      type={"private"}
                    />
                  ) : (
                    ""
                  )} */}
                <ConditionRoute
                  exact
                  path="/sub-admins"
                  component={SubAdmin}
                  type={"private"}
                />
                <ConditionRoute
                  exact
                  path="/kyc"
                  component={Kyc}
                  type={"private"}
                />
                <PrivateRoute
                  exact
                  path="/kycupdate/:id"
                  component={kycupdate}
                />
                {/* <PrivateRoute exact path="/2fa" component={twofactor} /> */}

                {/* {userData.auth.user.airdrop == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/airdroplist"
                    component={Airdroplist}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.airdrop == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/airdrop"
                    component={Airdrop}
                    type={"private"}
                  />
                ) : (
                  ""
                )} */}

                {userData.auth.user.passbook == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/passbook"
                    component={passBook}
                    type={"private"}
                  />
                ) : (
                  ""
                )}

                {/* {userData.auth.user.feeandrefferal == true ||
                  userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/feesowntoken"
                    component={FeesOwnToken}
                    type={"private"}
                  />
                  ) : (
                    ""
                  )} */}

                {/* {userData.auth.user.refferalcommisonhistory == true ||
                    userData.auth.user.role == "superadmin" ? (
  
                  <ConditionRoute
                    exact
                    path="/referralcommission"
                    component={ReferralCommission}
                    type={"private"}
                  />
                  ) : (
                    ""
                  )} */}

                {userData.auth.user.TradingBot == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/trading-bot"
                    component={TradingBot}
                    type={"private"}
                    menuKey={"2"}
                  />
                ) : (
                  ""
                )}
                {userData.auth.user.homepagecms == true ||
                userData.auth.user.role == "superadmin" ? (
                  <ConditionRoute
                    exact
                    path="/homepagecms"
                    component={LaunchpadCms}
                    type={"private"}
                  />
                ) : (
                  ""
                )}
              </Switch>
              <Route exact path="*" component={NotFound} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
// export default dashboardRoutes;
